import { useResourceShow } from '@koopajs/react';
import { IDocument } from 'types';
import { Stack, Box } from '@mui/material';
import { ErrorMessage, Loading } from '@koopajs/mui';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface IDocumentIframeS3Props {
  id: string;
  sxIframe?: SxProps<Theme>;
  sxLoadingContainer?: SxProps<Theme>;
}

export const DocumentIframeS3: React.FC<IDocumentIframeS3Props> = (props) => {
  const { id, sxIframe, sxLoadingContainer } = props;

  const { resource, isProcessing, errorMessage } = useResourceShow<IDocument>({
    path: '/documents',
    id
  });

  const extractedDocumentErrorMessage = extractErrorMessage(errorMessage);

  if (isProcessing) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ ...sxLoadingContainer }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'static', zIndex: 'unset' }} />
      </Stack>
    );
  }

  if (errorMessage) {
    return <ErrorMessage error={extractedDocumentErrorMessage} />;
  }

  return <Box component="iframe" src={resource?.attachedFileUri} sx={sxIframe} data-cy="document-iframe" />;
};
