import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useUserUpdate, useUserShow, useFileUpload, useLocale } from '@koopajs/react';
import { Settings, ErrorMessage, Loading } from '@koopajs/mui';
import { Box, Typography, Container, Divider } from '@mui/material';
import { LocaleSwitcher } from '../components/temp/LocaleSwitcherTemp';
import { useTimezone } from 'components/temp/useTimezoneTemp';
import { ImageEditor } from 'components/temp/ImageEditorTemp';
import { PageContainer } from 'components/temp/PageContainer';

const TimezoneLabels: { [k: string]: string } = {
  'America/New_York': 'New York, USA',
  'Europe/London': 'London, UK'
};

export const Account: React.FC = () => {
  const { user, errorMessage } = useUserShow();
  const { updateUser } = useUserUpdate();
  const { upload } = useFileUpload();

  const { t } = useLocale();
  const keyPrefix = 'Account';

  const handleProfileChange = useCallback(async (values: { [k: string]: unknown }): Promise<boolean> => {
    return await updateUser(values);
  }, []);

  const handleProfilePictureChange = useCallback(async (photo: Blob): Promise<void> => {
    const profilePicture = await upload(photo, 'cdn');
    await handleProfileChange({ profilePicture });
  }, []);

  const handleSignaturePictureChange = useCallback(async (photo: Blob): Promise<void> => {
    const signaturePicture = await upload(photo, 'cdn');
    await handleProfileChange({ signaturePicture });
  }, []);

  if (!user) {
    return <Loading sx={{ backgroundColor: 'transparent' }} />;
  }

  const name = user.firstname || user.lastname ? `${user.firstname || ''} ${user.lastname || ''}` : 'Unknown';

  const { timezone, setTimezone } = useTimezone();

  const handleSetTimezone = useCallback(async (data: { [k: string]: unknown }): Promise<boolean> => {
    await setTimezone(data.timezone as string);
    return true;
  }, []);

  const helmetTitle = `${t(keyPrefix + '.helmetTitle')} - PANORAMA`;

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Typography variant="h1" sx={{ fontSize: 24 }}>
          {t(keyPrefix + '.title')}
        </Typography>
        <ErrorMessage error={errorMessage} />
        <Box sx={{ py: 2 }}>
          <Settings.PhotoEditor
            i18n={{ keyPrefix: keyPrefix + '.SettingsPhotoEditor' }}
            photoUrl={user.profilePicture || ''}
            handleValueChange={handleProfilePictureChange}
          />
        </Box>
        <Divider />
        <Box sx={{ py: 2 }}>
          <Settings.FieldEditor
            i18n={{ keyPrefix: 'Account.SettingsFieldEditorName' }}
            uniqueDialogId="Name"
            value={name}
            fields={[
              { key: 'firstname', label: 'Firstname' },
              { key: 'lastname', label: 'Lastname' }
            ]}
            defaultValues={{ firstname: user.firstname, lastname: user.lastname }}
            handleValueChange={handleProfileChange}
          />
        </Box>
        <Divider />
        {/* TODO: SWAP TO KOOPA COMPONENT WHEN https://github.com/iwnstudio/koopa/pull/478 MERGES */}
        <LocaleSwitcher i18n={{ keyPrefix: 'koopaCore:SettingsLocaleSwitcher' }} />
        <Divider />
        <Box sx={{ py: 2 }}>
          <Settings.FieldEditor
            uniqueDialogId="TimezoneSwitcher"
            value={TimezoneLabels[timezone]}
            fields={[
              {
                key: 'America/New_York',
                label: TimezoneLabels['America/New_York']
              },
              {
                key: 'Europe/London',
                label: TimezoneLabels['Europe/London']
              }
            ]}
            radioFieldName="timezone"
            inputType="radioButton"
            handleValueChange={handleSetTimezone}
            i18n={{ keyPrefix: `Account.SettingsFieldEditorTimezone` }}
          />
        </Box>
        <Divider />
        <Box sx={{ py: 2 }}>
          <Settings.ReadOnly
            i18n={{ keyPrefix: 'Account.SettingsReadOnlyEmail' }}
            label="Email Address"
            value={(user.email as string) || 'Unknown'}
          />
        </Box>
        <Divider />
        <Box sx={{ py: 2 }}>
          <ImageEditor
            i18n={{ keyPrefix: keyPrefix + '.SettingsImageEditor' }}
            appLogoSrc={(user.signaturePicture as string) || ''}
            handleValueChange={handleSignaturePictureChange}
          />
        </Box>
        <Divider />
        <Box sx={{ py: 2 }}>
          <Settings.ReadOnly
            i18n={{ keyPrefix: 'Account.SettingsReadOnlyAccountId' }}
            label="Account ID"
            value={`u:${user.id}`}
          />
        </Box>
      </PageContainer>
    </>
  );
};
